<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
          <ion-back-button color="primary"></ion-back-button>
        </ion-buttons>
        <ion-title>Nuovo Account</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
        </ion-toolbar>
      </ion-header>
      <div id="container">  
        <form @submit="submit">
          <label>Nome:</label>
          <ion-input id="input" v-model="account.name"></ion-input>  
          <label>Indirizzo:</label>
          <ion-input id="input" v-model="account.address"></ion-input>
          <label>Città:</label>
          <ion-input id="input" v-model="account.city"></ion-input>
          <label>KM:</label>
          <ion-input id="input" v-model="account.km"></ion-input>
          <label>Pedaggio:</label>
          <ion-input id="input" v-model="account.travel_cost"></ion-input>
          <ion-button type="submit">SALVA</ion-button>
        </form>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
  import {
    IonBackButton, 
    IonButton,
    IonButtons,
    IonContent, 
    IonHeader,
    IonInput, 
    IonMenuButton, 
    IonPage,
    IonTitle, 
    IonToolbar,
    toastController,
  } from '@ionic/vue';

  export default {
    name: 'Folder',
    components: {
      IonBackButton,
      IonButton,
      IonButtons,
      IonContent,
      IonHeader,
      IonInput, 
      IonMenuButton,
      IonPage,
      IonTitle,
      IonToolbar
    },
    data: function(){
      return{
        account:{
          name:'',
          address:'',
          km:0,
          travel_cost:0,
          address2:'',
          city2:'',
          km2:0,
          travel_cost2:0,
          address3:'',
          city3:'',
          km3:0,
          travel_cost3:0,
        },
      }
    },
    methods:{
      submit(ev){
        ev.preventDefault();
        if(this.name!='' && this.address!='')
        this.axios.post(this.apiUrl + 'save-account', {account:this.account}, this.headers)
        .then(async (response) => {
          if(response.data.status_code==200){
            this.account.name='';
            this.account.address='';
            this.account.km=0;
            this.account.travel_cost=0;
            this.account.km2=0;
            this.account.travel_cost2=0;
            this.account.km3=0;
            this.account.travel_cost3=0;
            const toast = await toastController
            .create({
                color: 'success',
                message: response.data.message,
                duration: 2000
            })
            this.$router.push('/folder/accounts-list');
            return toast.present();
          }
        })
      }
    },
    created: function(){     
    }
  }
</script>
<style scoped>
  #input{
    background-color:#a9bdff48;
    border-radius:5px;
    margin:5px;
    width:90%;
  }
  form{
    padding:10px;
  }
</style>